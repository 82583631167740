import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />

      <section
        className="pt-3"
        style={{
          // marginTop: 176,
          backgroundColor: "#f5f5f5",
          height: "100%",
        }}
      >
        <div className="container mx-auto">
          <h1 className="display-8 text-center text-uppercase pb-3">
            Contact Us
          </h1>
        </div>
        <div className="container">
          <p className="text-center pb-5">
            Contact us through Facebook, Instagram, or Email. We look forward to
            hearing from you!
          </p>
          <div className="row">
            <div className="col-sm-4 text-center">
              <h3>Address</h3>
              <p>Phoenix, Arizona</p>
            </div>
            <div className="col-sm-4 text-center">
              <h3>Email</h3>
              <p>
                <a href="mailto:royalsunnydoodles@gmail.com">
                  royalsunnydoodles@gmail.com
                </a>
              </p>
            </div>
            <div className="col-sm-4 text-center">
              <h3>Social</h3>
              <div className="pl-3">
                <a
                  href="https://www.instagram.com/royalsunnydoodles/"
                  target="_blank"
                >
                  <i className="fab fa-instagram fa-2x mr-3"></i>
                </a>
                <a
                  href="https://www.facebook.com/royalsunnydoodles"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square fa-2x"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
